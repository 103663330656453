<template>
    <div class="document-card-wrapper" v-if="hasDocuments">
        <h2 class="text-primary" v-if="showTitle">Zie verder</h2>
        <div class="row">
            <div class="col-12 col-lg-4 d-flex align-items-stretch" v-for="document in documents" v-bind:key="document">
                <Card :cardItem="document" />
            </div>
        </div>
    </div>
</template>

<script>
    import Card from "@/components/Card";

    export default {
        name: 'DocumentCard',
        components: {
            Card
        },
        props: {
            'documents': Array,
            'show_title': Boolean,
        },
        computed: {
            showTitle() {
                if (typeof this.$props.show_title !== 'undefined') {
                    return this.$props.show_title;
                }

                return true;
            },
            hasDocuments() {
                if (typeof this.$props.documents !== 'undefined' && this.$props.documents.length > 0) {
                    return true;
                }

                return false;
            }
        }
    }
</script>